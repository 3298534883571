<template>
  <transition>
    <div
      v-if="filterToggle"
    >
      <b-form
        class="border-dashed"
      >
        <b-row>
          <b-col md="9">
            <b-row>
              <!--SKU-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.SKU)"
                >
                  <b-form-input
                    v-model="filters[Products.SKU]"
                    :placeholder="filterClass.getLabel(Products.SKU)"
                  />
                </b-form-group>
              </b-col>
              <!--Brand-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.BRAND)"
                >
                  <l-select-filter
                    v-model="filters[Products.BRAND]"
                    :field="filterClass.fields[Products.BRAND]"
                    :name="Products.BRAND"
                  />
                </b-form-group>
              </b-col>
              <!--Categories-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.CATEGORIES)"
                >
                  <l-select-filter
                    v-model="filters[Products.CATEGORIES]"
                    :field="filterClass.fields[Products.CATEGORIES]"
                    :name="Products.CATEGORIES"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!--Type-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.TYPE)"
                >
                  <l-select-filter
                    v-model="filters[Products.TYPE]"
                    :field="filterClass.fields[Products.TYPE]"
                    :name="Products.TYPE"
                  />
                </b-form-group>
              </b-col>
              <!--Barcode-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.BARCODE)"
                >
                  <b-form-input
                    v-model="filters[Products.BARCODE]"
                    :placeholder="filterClass.getLabel(Products.BARCODE)"
                  />
                </b-form-group>
              </b-col>
              <!--Tags-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.TAGS)"
                >
                  <l-select-filter
                    v-model="filters[Products.TAGS]"
                    :field="filterClass.fields[Products.TAGS]"
                    :name="Products.TAGS"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!--Name-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.NAME)"
                >
                  <b-form-input
                    v-model="filters[Products.NAME]"
                    :placeholder="filterClass.getLabel(Products.NAME)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Products.STATUS)"
                >
                  <l-select-filter
                    v-model="filters[Products.STATUS]"
                    :field="filterClass.fields[Products.STATUS]"
                    :name="Products.STATUS"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <label class="font-weight-bolder">{{ $t('Assets\' Rules') }}</label>
            <b-table-simple
              v-for="field in [
                Products.CAN_BE_PROCURED,
                Products.CAN_BE_RENTED_INDIVIDUALLY,
                Products.CAN_BE_SUB_RENTED,
                Products.CAN_BE_SOLD,
                Products.CAN_BE_TRANSFERRED
              ]"
              :key="field"
            >
              <b-row class="checkboxGroup">
                <b-col md="8">
                  <b-thead>
                    <label
                      :for="field"
                      class="checkboxLabel"
                    >
                      {{ filterClass.getLabel(Products.PRODUCT_RULES, field) }}
                    </label>
                  </b-thead>
                </b-col>
                <b-col
                  md="4"
                  class="d-flex justify-content-end"
                >

                  <b-form-checkbox
                    :id="field"
                    v-model="filters[Products.PRODUCT_RULES][field]"
                    class="checkbox ml-auto"
                    value="1"
                    :unchecked-value="null"
                  />
                </b-col>
              </b-row>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              variant="success"
              @click="onFilter"
            >
              <feather-icon icon="LFilterIcon" />
              {{ $t('Filter') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <div v-else>
      <span
        v-if="$route.query.hasOwnProperty('search')"
        class="border-dashed mr-1 d-inline-block"
      >
        <b>Search:</b> <span v-html="$route.query.search" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSearchFilter()"
        />
      </span>
      <span
        v-for="selectedFilter in selectedFilters"
        :key="selectedFilter.path.join('.')"
        v-if="selectedFilter.path.toString() !== 'status'"
        class="border-dashed mr-1 d-inline-block mb-1"
      >
        <span v-html="selectedFilter.value" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSelectedFilter(selectedFilter.path)"
        />
      </span>
    </div>
  </transition>
</template>

<script>
import {
  BForm, BButton, BRow, BCol,
  BFormGroup, BFormInput, BTableSimple, BThead, BFormCheckbox,
} from 'bootstrap-vue'
import LSelectFilter from '@/views/components/filters/formComponents/LSelectFilter.vue'
// eslint-disable-next-line import/no-cycle
import Products from '@/views/components/filters/fields/Products'

export default {
  name: 'CustomersFilterComponent',
  components: {
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    LSelectFilter,
    BFormInput,
    BTableSimple,
    BThead,
    BFormCheckbox,
  },
  props: {
    filterToggle: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: [Array, Object],
      default: () => ({}),
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    selectedFilters: {
      type: [Array],
      default: () => ([]),
    },
  },
  data() {
    return {
      filterCustomer: {},
    }
  },
  computed: {
    Products() {
      return Products
    },
  },
  methods: {
    onFilter() {
      this.$emit('onFilter', this.filter)
    },
    removeSelectedFilter(path) {
      this.filterClass.removeSelectedFilter(this.filters, path)
      this.$emit('onRemoveSelectedFilter', path)
    },
    removeSearchFilter() {
      this.$emit('searchQueryClear')
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
